import React from 'react';
import { node, bool } from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { breakpoints, headerHeight } from 'TMConstants';

const StyledMain = styled.main`
    overflow-x: hidden;
`;
// workaround for internet explorer as padding in main seems to be ignored, change to margin made it chrome to auto scroll to header component
const TopMargin = styled.div`
    width: 100%;
    ${(props) => `
        height: ${
            headerHeight.mobile +
            (props.hasBanner && 61) +
            (props.hasCrumbs && 84 + 75)
        }px;
    @media(min-width: ${breakpoints.mobileTablet}px){
        height: ${
            headerHeight.desktop +
            (props.hasBanner && 54) +
            (props.hasCrumbs && 84 + 50)
        }px;
    }`}
`;

const Main = ({ isBannerAllowed, isCrumbsAllowed, children }) => {
    const { banner } = useStaticQuery(graphql`
        {
            banner: datoCmsSiteBanner {
                showBanner
            }
        }
    `);

    return (
        <StyledMain>
            <TopMargin
                hasBanner={isBannerAllowed && banner.showBanner}
                hasCrumbs={isCrumbsAllowed}
            />
            {children}
        </StyledMain>
    );
};

Main.propTypes = {
    children: node.isRequired,
    isBannerAllowed: bool,
    isCrumbsAllowed: bool
};

Main.defaultProps = {
    isCrumbsAllowed: false,
    isBannerAllowed: false
};

export default Main;
