/* SEARCH-TAG: page-template  */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';
import Main from 'TMComponents/Layout/Main';
import FlexWrapper from 'TMComponents/FlexWrapper';
import ContentParser from 'TMComponents/ContentParser';
import { breakpoints } from 'TMConstants';
import Img from 'TMComponents/ImageDynamic';
import HeadTags from 'TMComponents/HeadTags';
import { fireGAEvent } from 'TMUtils/dataLayerPush';

const Container = styled(FlexWrapper)`
    flex-direction: column-reverse;
    padding: 40px 0;
    text-align: center;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        padding: 80px 0;
        flex-direction: row;
    }
`;

const Column = styled.div`
    width: 100%;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: calc(50% - 32px);
        max-width: 568px;
        margin: 0 16px;
    }
`;

const Image = styled(Img)`
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const NotFoundPage = ({ data: { content } }) => {
    // When the component has mounted, if there is a window.datalayer (GTM) then push a view event
    useEffect(() => {
        if (window?.location.href) {
            fireGAEvent(
                '404 error',
                'Web-Web-404-View',
                'website interaction',
                window.location.href
            );
        }
    }, []);
    return (
        <>
            <HeadTags>
                <title>Not Found</title>
            </HeadTags>
            <Main>
                <Container justify="center" align="center" wrap>
                    <Column>
                        <ContentParser copy={content.content} />
                    </Column>
                    <Column>
                        <Image imageData={content.image} />
                    </Column>
                </Container>
            </Main>
        </>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    query {
        content: datoCms404Page {
            content
            image {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
        }
    }
`;

NotFoundPage.propTypes = {
    data: shape({
        content: shape({
            content: string,
            image: shape(imageProps)
        })
    }).isRequired
};

export default NotFoundPage;
